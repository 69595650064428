<template>
  <AntRow>
    <AntCol :span="24">
      <AntRow v-if="(permissionData.UserList_CreateUser || []).includes(loginUserData.permission)" justify="end">
        <AntButton
          type="primary"
          html-type="submit"
          @click="() => onToggleDrawer('createUser')"
        >
          Create User
        </AntButton>
      </AntRow>

      <!-- <p><b>showSearchText:</b> {{ showSearchText }}</p>
      <p><b>searchState:</b> {{ searchState }}</p> -->

      <AntRow :gutter="16" class="search-row">
        <AntCol :span="6">
          <AntInput
            placeholder="User ID"
            v-model:value="searchState.userId"
            class="search-input"
            allowClear
          />
        </AntCol>

        <AntCol :span="6">
          <AntSelect
            placeholder="User Level"
            v-model:value="searchState.permission"
            :options="PERMISSION_OPTIONS"
            class="search-input"
            allowClear
          />
        </AntCol>

        <AntCol :span="4">
          <AntButton
            type="primary"
            html-type="submit"
            @click="onSearch"
          >
            Search
          </AntButton>
        </AntCol>
      </AntRow>

      <!-- <p><b>editableData:</b> {{ editableData }}</p> -->
      <AntTable
        :columns="columns"
        :dataSource="displayedDataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 50,
          pageSizeOptions: ['50', '100', '300'],
          showSizeChanger: true,
        }"
        :showSorterTooltip="false"
        bordered
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="showSearchText && column.dataIndex === 'userId'">
            <template
              v-for="(fragment, i) in text
                .split(new RegExp(`(?<=${showSearchText})|(?=${showSearchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === showSearchText.toLowerCase()"
                class="highlight"
                :key="i"
              >
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </template>

          <template v-else>
            <template v-if="column.dataIndex === 'permission'">
              <AntSelect
                v-if="record.permission !== 'admin' && editableData[record.key]"
                style="width: 50%"
                v-model:value="editableData[record.key].permission"
                :options="PERMISSION_OPTIONS"
              />
              <div v-else>{{ PERMISSION_NAME[text] }}</div>
            </template>

            <template v-if="column.dataIndex === 'locked'">
              <AntSwitch
                v-if="editableData[record.key]"
                unCheckedChildren="Lock"
                checkedChildren="Active"
                :checked="!editableData[record.key].locked"
                @change="val => editableData[record.key].locked = !val"
              />
              <AntSwitch
                v-else
                unCheckedChildren="Lock"
                checkedChildren="Active"
                :checked="!text"
                disabled
              />
            </template>

            <template v-if="column.dataIndex === 'operation'">
              <div class="editable-row-operations">
                <span v-if="editableData[record.key]">
                  <a @click="onSave(record.key)">Save</a>
                  <AntDivider type="vertical" />
                  <a @click="onCancel(record.key)">Cancel</a>
                </span>
                <span v-else>
                  <a
                    v-if="(permissionData.UserList_ResetPassword || []).includes(loginUserData.permission)"
                    @click="onToggleDrawer('resetPassword', record)">
                    Reset Password
                  </a>
                  <AntDivider type="vertical" />
                  <span v-if="((permissionData.UserList_Delete || []).includes(loginUserData.permission) && record.permission !== 'admin') || loginUserData.userId === 'effie'">
                    <AntPopconfirm
                      v-if="dataSource.length"
                      :title="`Sure to delete user ${record.key}?`"
                      @confirm="onDelete(record.key)"
                    >
                      <a>Delete</a>
                    </AntPopconfirm>
                  </span>
                  <AntDivider type="vertical" />
                  <a
                    v-if="(permissionData.UserList_Edit || []).includes(loginUserData.permission)"
                    @click="onEdit(record.key)"
                  >
                    Edit
                  </a>
                </span>
              </div>
            </template>
          </template>

        </template>
      </AntTable>

    </AntCol>
  </AntRow>

  <AntDrawer
    :title="drawerState.title"
    :width="720"
    :visible="drawerState.visible"
    :bodyStyle="{ paddingBottom: '80px' }"
    @close="e => onToggleDrawer()"
  >
    <!-- <p><b>drawerState.record:</b> {{ drawerState.record }}</p> -->
    <FormTemplate
      v-if="drawerState.visible"
      :formState="drawerState.record"
      :formFields="formFields"
      :onSubmit="formState => onSubmit(drawerState.action, formState)"
    />
    <AntAlert
      v-if="!!drawerState.alert.message"
      class="warning-message"
      :type="drawerState.alert.type"
      :message="drawerState.alert.message"
      showIcon
    />
  </AntDrawer>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onBeforeMount,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Table as AntTable,
  Input as AntInput,
  Button as AntButton,
  Select as AntSelect,
  Switch as AntSwitch,
  Divider as AntDivider,
  Popconfirm as AntPopconfirm,
  Drawer as AntDrawer,
  Alert as AntAlert,
  message as antMessage,
} from 'ant-design-vue'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

import {
  PERMISSION_NAME,
  PERMISSION_OPTIONS,
} from '@/constants/testerPortal'

import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
} from '@/api/testerportal'

export default {
  name: 'UserList',
  components: {
    AntRow,
    AntCol,
    AntTable,
    AntInput,
    AntButton,
    // AntTextarea: AntInput.TextArea,
    AntSelect,
    AntSwitch,
    AntDivider,
    AntPopconfirm,
    AntDrawer,
    AntAlert,
    FormTemplate,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    permissionData: Object,
    requestResponseData: Object,
    onChangeLoginUserData: Function,
    selectedUserLevel: {
      type: String,
      required: false,
    },
    selectedSourceLine: {
      type: Object,
      required: false,
    },
    ratioData: {
      type: Object,
      required: false,
    },
    loginPlayerData: {
      type: Object,
      required: false,
    },
    onChangeLoginPlayerData: {
      type: Function,
      required: false,
    },
    onChangePermissionData: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const showSearchText = ref('')
    const searchState = reactive({})
    const drawerState = reactive({ record: {}, alert: {} })

    const columns = [
      {
        title: 'User ID',
        dataIndex: 'userId',
        width: '33%',
      },
      {
        title: 'Permission Level',
        dataIndex: 'permission',
        width: '33%',
      },
      {
        title: 'Status',
        dataIndex: 'locked',
        width: '33%',
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: '34%',
      },
    ]

    const formFields = computed(() => [
      {
        id: 'userId',
        label: 'User ID',
        // defaultValue: drawerState.record.key,
        element: 'input',
        inputType: 'text',
        maxlength: 21,
        rules: [
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: 'Please enter the correct format',
            trigger: 'blur',
          },
          drawerState.action === 'createUser' ? {
            required: true,
            message: 'Required',
          } : {},
        ],
        disabled: drawerState.action === 'resetPassword',
      },
      ...(drawerState.action === 'resetPassword' ? [] : [{
        id: 'permission',
        label: 'User Level',
        // defaultValue: drawerState.record.permission,
        element: 'select',
        options: PERMISSION_OPTIONS,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      }]),
      {
        id: 'password',
        label: `${drawerState.action === 'resetPassword' ? 'New ' : ''}Password`,
        // defaultValue: undefined,
        element: 'input-password',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
    ])

    const dataSource = ref([])
    const displayedDataSource = ref([])

    const editableData = reactive({})
    const editableFields = ['permission', 'locked']

    const setDataSource = async () => {
      const { data: usersData } = await getUsers()
      dataSource.value = usersData.map(d => ({ key: d.userId, ...d }))
      displayedDataSource.value = dataSource.value
    }

    onBeforeMount(setDataSource)

    const onSearch = () => {
      if (Object.values(searchState).every(d => !d)) {
        showSearchText.value = null
        displayedDataSource.value = dataSource.value
      } else {
        showSearchText.value = searchState.userId
        displayedDataSource.value = dataSource.value.filter((d) => {
          let matched = true
          if (searchState.userId) matched = matched && d.key.includes(searchState.userId)
          if (searchState.permission) matched = matched && d.permission === searchState.permission
          return matched
        })
      }
    }

    const onDelete = async (key) => {
      const messageKey = `deleteUser-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const { data, message } = await deleteUser(key)

      if (data) {
        delete editableData[key]
        dataSource.value = dataSource.value.filter(d => d.userId !== key)
        displayedDataSource.value = dataSource.value

        antMessage.success({
          key: messageKey,
          content: `Delete user [${key}] successfully`,
        })
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to delete user [${key}]: ${message}`,
        })
      }
    }

    const onEdit = (editKey) => {
      const data = dataSource.value.find(d => editKey === d.key)
      editableData[editKey] = Object.keys(data).reduce((acc, key) => {
        if (editableFields.includes(key)) acc[key] = data[key]
        return acc
      }, {})
    }

    const onSave = async (key) => {
      const messageKey = Date.now()
      antMessage.loading('', { key: messageKey })

      const { data: updateUserData, message } = await updateUser({
        userId: key,
        ...editableData[key],
        ...(editableData[key].locked === false && { failedLoginAttempts: 0 }),
      })

      if (updateUserData) {
        Object.assign(dataSource.value.find(d => key === d.key), editableData[key])
        delete editableData[key]

        antMessage.success({
          key: messageKey,
          content: `Update user [${key}] successfully`,
        })

        if (updateUserData.userId === props.loginUserData.userId) {
          props.onChangeLoginUserData(updateUserData) // update permission
        }
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update user [${key}]: ${message}`,
        })
      }
    }

    const onCancel = (key) => {
      delete editableData[key]
    }

    const actionTitleMap = {
      createUser: 'Create User',
      resetPassword: 'Reset Password',
    }

    const onToggleDrawer = (action, record) => {
      drawerState.visible = !drawerState.visible
      if (action) {
        if (record) drawerState.record = { ...record }
        drawerState.action = action
        drawerState.title = actionTitleMap[action]
      } else {
        drawerState.record = {}
        drawerState.alert = {}
      }
    }

    const onSubmit = async (action, formState) => {
      const messageKey = Date.now()
      antMessage.loading('', { key: messageKey })

      let data
      let message
      if (action === 'resetPassword') {
        const { userId, password } = formState;
        ({ data, message } = await updateUser({ userId, password }))
      } else {
        ({ data, message } = await createUser(formState))
      }

      if (message) {
        drawerState.alert = {
          type: 'error',
          message,
        }
      } else {
        const { userId } = data
        let content
        if (action === 'resetPassword') {
          content = `Reset user [${userId}] password successfully`
        } else {
          content = `Create user ID: ${userId} successfully`
          dataSource.value = [...dataSource.value, { key: userId, ...data }]
          displayedDataSource.value = dataSource.value
          delete searchState.userId
          delete searchState.permission
        }
        antMessage.success({
          key: messageKey,
          content,
        })
        drawerState.alert = {}
        onToggleDrawer()
      }
    }

    return {
      searchState,
      showSearchText,
      onSearch,
      dataSource,
      displayedDataSource,
      columns,
      editableData,
      PERMISSION_OPTIONS,
      PERMISSION_NAME,
      onDelete,
      onEdit,
      onSave,
      onCancel,
      formFields,
      drawerState,
      onToggleDrawer,
      onSubmit,
    }
  },
}
</script>

<style scoped>
.search-row {
  margin-bottom: 28px;
}

.search-input {
  width: 100%;
}
</style>
